import React, {Component} from 'react';
import Label from "./label";
import {List,Modal,Toast} from 'antd-mobile';
import Config from '../../config';
import HttpHelper from "../../common/HttpHelper";
import IDataEntry from "./IDataEntry";
class FileSelect extends Component {
    state = {
        value:[],        
        fileList :{
            'W':'bg-blue',
            'doc':'bg-blue',
            'X':'bg-green',
            'pdf':'bg-red',
            'P':'bg-orange'
        },   
    };
    componentWillReceiveProps(nextProps) {       
        if (nextProps.field_value != this.state.value) {         
            this.setState({
                value: nextProps.field_value||[]
            })           
        }
    }
    // 点击上传
    handleUpload(){
        document.getElementById("fileBtn"+this.props.currIndex).click();
    };
    // 上传文件
    async uploadFile(e){   
        const files = e.target.files[0];
        let file_type = await this.getFileType(files);   
        Toast.loading('上传中');        
        const formData = new FormData(); 
        formData.append('access_token',Config.TOKEN);
        formData.append("file",files);     
        let url = Config.api_common_upload;
        if(file_type == 'video'){
            url = Config.api_video_upload;
        }             
        HttpHelper.post(url,formData).then(response=>{
            let res = response.data;
            Toast.hide();
            if(res.sta == 0){  
                let urls = '';
                if(file_type == 'video'){
                    urls = res.data.url;
                }else{
                    urls = res.data.urls[0];
                }                             
                const params = {
                    name:files.name,
                    path:urls,
                    type:file_type
                }
                this.state.value.push(params);
                this.setState({value:this.state.value});
                this.props.onFieldChange(this.state.value,this.props.field_key);                
            }else{           
                Toast.fail(res.msg);
            }
        })     
    };
    // 获取文件类型
    getFileType(files) {       
        let fileName = files.name;      
        let lastIndex = fileName.lastIndexOf('.');
        let str = fileName.substring(lastIndex,fileName.length);
        str = str.toLocaleLowerCase();  
        return new Promise((resolve)=>{         
            const fileSize = files.size / 1024 / 1024;
            if(fileSize > 10){
                Modal.alert('温馨提示','文件大小不能超过10M,如需上传，可前此网站（https://pdfcompressor.com）压缩文件 ',[
                    { text: '取消', style: 'default'},
					{ text: '去压缩文件', onPress: () => {	
						window.open('https://pdfcompressor.com');
					}},
                ]);
                return;
            }
            let type = str;                                
            if (str.indexOf('.doc') > -1) type = 'doc';
            else if (str.indexOf('.xls') > -1) type = 'X';
            else if (str.indexOf('.ppt') > -1) type = 'P';
            else if (str.indexOf('.pdf') > -1) type = 'pdf'; 
            else if(str.indexOf('.png') > -1 || str.indexOf('.jpg') > -1 ||  str.indexOf('.jpeg') > -1 ||  str.indexOf('.gif') > -1){
                type = 'img';
            }else if(str.indexOf('.mp3' > -1) || str.indexOf('.mp4') > -1 || str.indexOf('.webm') > -1 || str.indexOf('.wav') > -1) {
                type = 'video';
            }
            else{
                Modal.alert('温馨提示','仅支持文件、视频、图片，请重新上传',[{text:'我知道了'}]);
                return;
                
            }
            resolve(type);                                 
        })        
    };
    // 删除文件
    handleDelete(index){
        this.state.value.splice(index,1);
        this.setState({value:this.state.value});
        this.props.onFieldChange(this.state.value,this.props.field_key);       
    };
    render(){
        return (
            <List className="enroll-label" renderHeader={() => <Label {...this.props} />}>          
                <input style={{display:'none'}} type="file" id={"fileBtn"+this.props.currIndex} 
                onChange={(e)=>{this.uploadFile(e)}} ></input>               
                {
                    this.renderContent()
                }
            </List>
        )
    };
    renderContent(){
        let msg = '';
        if(this.state.value && this.state.value.length > 0){
            msg = this.state.value.map((item,index)=>{
                let colorType = 'bg-default';                       
                if(this.state.fileList[item.type]){
                    colorType = this.state.fileList[item.type];
                }	
                
                if(item.type == 'img'){
                    return (
                        <div className="enroll-img-box">                           
                            <i className="delete-file iconfont icon-bg-deletion" onClick={(e)=>{
                                e.stopPropagation();
                                this.handleDelete(index);     
                            }}></i>
                            <img className='upload-img' src={item.path}></img>
                        </div>                        
                    )
                }else if(item.type == 'video'){
                    return (
                        <div className='enroll-img-box enroll-video-box'>
                            <i className="delete-file iconfont icon-bg-deletion" onClick={(e)=>{
                                e.stopPropagation();
                                this.handleDelete(index);    
                            }}></i>
                            <video className="video-banner" src={item.path} controls="controls"></video>
                        </div>
                    )
                }else{
                    return (
                        <div className="enroll-file-box" key={index} onClick={()=>{
                            window.open(item.path);
                        }}>
                            {
                                !this.props.disabled ?
                                <i className="delete-file iconfont icon-bg-deletion" onClick={(e)=>{
                                    e.stopPropagation();
                                    this.handleDelete(index);                               
                                }}></i> : ''
                            }                               
                            <div className={["file-type-tag "+colorType].join('')}>{item.type}</div>
                            <div className="upload-file-name">{item.name}</div>
                        </div>
                    )
                }               
            })          
        }else if(this.props.disabled){
            msg = <span className="enroll-file-none">无</span>
        }else{
            msg = <div className="enroll-upload-file" onClick={(e)=>{this.handleUpload(e)}}>+ 点此上传</div>
        }
        return msg;
    }
}

FileSelect.propTypes = IDataEntry;
export default FileSelect;